<script setup lang="ts">
import TenantSelector from "./TenantSelector.vue";
import Version from "./Version.vue";
import { RouteRecordName, RouterLink, useRoute } from "vue-router";
import { useDescope } from "@descope/vue-sdk";
import { LogOut, Milestone } from "lucide-vue-next";
import { useTenantStore } from "@/stores/tenant";
import { useConfigCatStore } from "@/stores/configCat";
import { useAuthStore } from "@/stores/auth";
import ChatSessions from "./ChatSessions.vue";
import logo from "@/assets/logo.png";
import { Button } from "@/components/ui/button";

const { logout } = useDescope();
const tenantStore = useTenantStore();
const configCatStore = useConfigCatStore();
const auth = useAuthStore();

type CanAccessFunction = (tenantStore: any) => boolean;

const canAccessRoute = (route: any) => {
  if (route.meta.showInSidebar === false) {
    return false;
  }
  if (route.path === "/user-management") {
    return (
      configCatStore.userManagementTab &&
      tenantStore.tenantAdmin &&
      !auth.isInsideTeams
    );
  }
  if (route.path === "/catalog-management") {
    return configCatStore.catalogManagementTab && tenantStore.tenantAdmin;
  }
  return (route.meta.canAccess as CanAccessFunction)(tenantStore);
};

const assignClass = (routeName: RouteRecordName | undefined) => {
  if (routeName === "Data sources") {
    return "data_sources_button";
  }
  if (routeName === "Saved prompts") {
    return "saved_prompts_button";
  }
  return undefined;
};

const currentRoute = useRoute();
const isRouteSelected = (routeName: RouteRecordName | undefined) =>
  routeName && routeName === currentRoute.name;
</script>

<template>
  <div
    class="sidebar text-primary50 bg-foreground flex h-screen max-h-screen gap-3 flex-col w-64 max-w-64 z-40 px-4 pt-8 pb-3 shrink-0"
  >
    <div class="logo mb-3">
      <RouterLink to="/" class="flex items-center gap-3">
        <img alt="Playground" :src="logo" class="h-8 w-8" />
        <span
          class="text-background text-2xl font-spartan -leading-2 font-semibold"
          >Playground</span
        >
      </RouterLink>
    </div>
    <TenantSelector />
    <nav
      aria-label="sidebar"
      class="navbar flex flex-col justify-between w-full min-h-0 flex-1"
    >
      <div class="menu_top flex flex-col gap-5 w-full overflow-hidden">
        <div class="menu_items font-spartan font-normal text-lg shrink-0">
          <RouterLink
            v-for="route in $router.getRoutes()"
            :to="route.path"
            :key="route.name"
            class="group"
          >
            <div
              v-if="canAccessRoute(route)"
              class="flex items-center gap-3 rounded-full px-3 py-2 transition-all"
              :id="assignClass(route.name)"
            >
              <component
                :is="route.meta.icon"
                class="h-5 w-5"
                :class="{ 'text-background': isRouteSelected(route.name) }"
              />
              <p
                class="group-hover:underline"
                :class="{ 'text-background': isRouteSelected(route.name) }"
              >
                {{ route.name }}
              </p>
            </div>
          </RouterLink>
        </div>
        <ChatSessions />
      </div>
      <div class="menu_bottom shrink-0">
        <Button
          variant="link"
          v-if="tenantStore.tenantElqano"
          class="version-btn flex items-center gap-3 px-3 pt-4 pb-0 transition-all text-muted-foreground text-xs"
        >
          <Milestone class="h4 w-4" />
          <Version />
        </Button>
        <Button
          variant="link"
          v-if="!auth.isInsideTeams"
          class="logout-btn font-spartan font-medium text-base text-primary50 flex items-center gap-3 rounded-lg px-3 py-2 transition-all"
          @click="logout()"
        >
          <LogOut class="h-5 w-5" />
          Logout
        </Button>
      </div>
    </nav>
  </div>
</template>
