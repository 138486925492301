import apiClient from "@/services/api_client";
import { useAuthStore } from "@/stores/auth";
import { isSessionTokenExpired } from "@descope/vue-sdk";
import { nextTick, reactive, ref } from "vue";

const websocket = ref<WebSocket | null>(null);
const isWebSocketOpen = ref(false);
const botResponseIndex = ref<number | null>(null);
const descopeTokenInWebsocket = ref<string | null>(null);
const messages = ref<
  Array<{
    type: string;
    content: string;
    isLoading: boolean;
    sources?: any;
    flowId?: string;
  }>
>([]);
const promptAnswers = reactive<{ [key: string]: any }>({});
const isProcessingMessage = ref(false);
const isAbleToStop = ref(false);
const scribeProgress = ref<{ value: number; message: string }>({
  value: 0,
  message: "",
});

const connectWebSocket = async (url: string) => {
  const auth = useAuthStore();
  const token = await auth.getSessionToken();
  descopeTokenInWebsocket.value = token;

  const host = apiClient.host || location.host;
  websocket.value = new WebSocket(
    `wss://${host}/ws/${url}?token=${token}&teams=${auth.isInsideTeams}`
  );
  setupWebSocketHandlers();
};

const disconnectWebSocket = (code: number = 1000, reason: string = "") => {
  if (websocket.value) {
    websocket.value.close(code, reason);
  }
};

const setupWebSocketHandlers = () => {
  if (websocket.value) {
    websocket.value.onmessage = handleWebSocketMessage;
    websocket.value.onopen = handleWebSocketOpen;
    websocket.value.onerror = handleWebSocketError;
    websocket.value.onclose = handleWebSocketClose;
  }
};

const handleWebSocketMessage = async (event: MessageEvent) => {
  const botMessageContent = event.data;

  try {
    const parsedMessage = JSON.parse(botMessageContent);
    switch (parsedMessage.type) {
      case "sources":
        if (messages.value.length > 0) {
          const lastMessage = messages.value[messages.value.length - 1];
          lastMessage.sources = parsedMessage.sources;
        }
        break;
      case "completion":
        if (messages.value.length > 0) {
          const lastMessage = messages.value[messages.value.length - 1];
          if (
            (lastMessage.content.trim() === "..." ||
              lastMessage.content.trim() === "") &&
            !lastMessage.sources
          ) {
            messages.value.pop();
          }
        }

        isProcessingMessage.value = false;
        isAbleToStop.value = false;
        break;
      case "flow_id":
        const lastMessage = messages.value[messages.value.length - 1];
        lastMessage.flowId = parsedMessage.flow_id;
        break;
      case "prompt_answer":
        promptAnswers[parsedMessage["prompt_id"]] = {
          content: parsedMessage["content"],
          completed: false,
        };
        break;
      case "prompt_answer_completed":
        promptAnswers[parsedMessage["prompt_id"]] = {
          content: promptAnswers[parsedMessage["prompt_id"]]["content"],
          completed: true,
        };
        break;
      case "error":
        // code 4000 represents a generic backend error from the client side
        disconnectWebSocket(4000);
        messages.value.pop();
        addErrorMessage(parsedMessage.content);
        break;
      case "able_to_stop":
        isAbleToStop.value = true;
        break;
      default:
        updateBotMessage(parsedMessage.content);
        break;
    }
  } catch (e) {
    console.error("Error parsing message:", e);
  }
  await nextTick();
};

const addBotMessage = (botMessage: {
  type: string;
  content: string;
  isLoading: boolean;
  sources?: any;
}) => {
  messages.value.push(botMessage);
  botResponseIndex.value = messages.value.length - 1;
};

const addErrorMessage = (content: string) => {
  messages.value.push({
    type: "error",
    content: content,
    isLoading: false,
  });
  botResponseIndex.value = null;
  isProcessingMessage.value = false;
};

const updateBotMessage = (content: string) => {
  if (botResponseIndex.value !== null) {
    messages.value[botResponseIndex.value].content = content;
    messages.value[botResponseIndex.value].isLoading =
      content.trim() === "..." || content.trim() === "";
  } else {
    addBotMessage({
      type: "bot",
      content,
      isLoading: content.trim() === "..." || content.trim() === "",
    });
  }
};

const handleWebSocketOpen = () => {
  console.log("WebSocket connection established");
  isWebSocketOpen.value = true;
};

const handleWebSocketError = (error: Event) => {
  console.error("WebSocket error:", error);
};

const handleWebSocketClose = (event: CloseEvent) => {
  console.log("WebSocket connection closed");
  isWebSocketOpen.value = false;

  if (event.code !== 1000) {
    // 1000 is the normal closure code
    console.error(`WebSocket closed with code ${event.code}: ${event.reason}`);
  }
};

const waitForWebSocketConnection = (): Promise<void> => {
  return new Promise((resolve) => {
    const checkWebSocketStatus = () => {
      if (isWebSocketOpen.value) {
        resolve();
      } else {
        setTimeout(checkWebSocketStatus, 100); // Check again in 100ms
      }
    };
    checkWebSocketStatus();
  });
};

const sendMessage = async (
  messageContent: string,
  tenantId: string,
  session_id: string,
  skill: string | undefined,
  datasourceIds: number[],
  microsoftToken: string | undefined,
  fromTeams: boolean = false
) => {
  const userMessage = {
    type: "user",
    content: messageContent,
    isLoading: false,
    session_id: session_id,
  };
  messages.value.push(userMessage);
  botResponseIndex.value = null;
  isProcessingMessage.value = true;
  const botLoadingMessage = { type: "bot", content: "", isLoading: true };
  messages.value.push(botLoadingMessage);
  botResponseIndex.value = messages.value.length - 1;

  await send(
    messageContent,
    undefined,
    tenantId,
    session_id,
    skill,
    datasourceIds,
    microsoftToken,
    fromTeams,
    "user_message"
  );
};

const sendPrompt = async (
  messageContent: string,
  prompt_id: string,
  tenantId: string,
  session_id: string,
  skill: string | undefined,
  datasourceIds: number[],
  microsoftToken: string | undefined,
  fromTeams: boolean = false
) => {
  botResponseIndex.value = null;
  botResponseIndex.value = messages.value.length - 1;

  await send(
    messageContent,
    prompt_id,
    tenantId,
    session_id,
    skill,
    datasourceIds,
    microsoftToken,
    fromTeams,
    "prompt"
  );
};

const send = async (
  messageContent: string,
  prompt_id: string | undefined,
  tenantId: string,
  session_id: string,
  skill: string | undefined,
  datasourceIds: number[],
  microsoftToken: string | undefined,
  fromTeams: boolean = false,
  sendType: string = "user_message"
) => {
  botResponseIndex.value = null;
  botResponseIndex.value = messages.value.length - 1;

  if (
    isWebSocketOpen.value === false ||
    isSessionTokenExpired(descopeTokenInWebsocket.value ?? undefined)
  ) {
    isWebSocketOpen.value = false;
    await connectWebSocket("chat");
    await waitForWebSocketConnection();
  }

  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    const messageData = JSON.stringify({
      type: sendType,
      ...(prompt_id && { prompt_id: prompt_id }),
      content: messageContent,
      session_id: session_id,
      tenantId: tenantId,
      skill: skill,
      data_source_ids: datasourceIds,
      token: { accessToken: microsoftToken, fromTeams: fromTeams },
    });
    websocket.value.send(messageData);
  } else {
    console.error("WebSocket is not open");
  }
};

const sendStopSignal = () => {
  if (websocket.value && websocket.value.readyState === WebSocket.OPEN) {
    const messageData = JSON.stringify({
      type: "stop",
    });
    websocket.value.send(messageData);
    console.log("Stop signal sent");
  } else {
    console.error("WebSocket is not open");
  }
};

export {
  connectWebSocket,
  disconnectWebSocket,
  isAbleToStop,
  isProcessingMessage,
  isWebSocketOpen,
  messages,
  promptAnswers,
  scribeProgress,
  sendMessage,
  sendPrompt,
  sendStopSignal,
  waitForWebSocketConnection,
  websocket,
};
