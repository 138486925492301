<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  DialogHeader,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useTenantStore } from "@/stores/tenant";
import { Plus, Loader2, Eye, EyeOff, Lightbulb, Brain } from "lucide-vue-next";
import { onMounted, ref, watch } from "vue";
import { Visibility, Skill, Skills } from "@/types";
import { createSavedPrompt } from "@/services/saved_prompts";
import { getSkills } from "@/services/skill";

const props = defineProps<{
  loadSavedPrompts: Function;
  isGlobal: boolean;
}>();

const tenantStore = useTenantStore();

const newSavedPromptName = ref<string>("");
const newSavedPromptContent = ref<string>("");

const isAdmin = tenantStore.tenantAdmin;
const visibilityValues = Object.values(Visibility);

const selectedVisibility = ref<string>(Visibility.PRIVATE);
const skills = ref<Skill[]>([]);
const selectedSkill = ref<string>(Skills.CHAT_WITH_DOCUMENTS);

const errorMessage = ref<string | null>(null);
const isDialogOpen = ref(false);
const isLoading = ref(false);

async function handleCreateSavedPrompt() {
  if (!tenantStore.tenantId) {
    return;
  }
  isLoading.value = true;

  await createSavedPrompt(
    newSavedPromptName.value,
    newSavedPromptContent.value,
    selectedVisibility.value,
    props.isGlobal ? "__global__" : tenantStore.tenantId,
    selectedSkill.value
  );

  await props.loadSavedPrompts();

  newSavedPromptName.value = "";
  newSavedPromptContent.value = "";
  isDialogOpen.value = false;
  isLoading.value = false;
}

onMounted(async () => {
  if (!props.isGlobal || !tenantStore.tenantId) {
    return;
  }
  const response = await getSkills(tenantStore.tenantId);
  skills.value = response.skills;
});

watch(newSavedPromptName, (newName) => {
  if (!newName) {
    errorMessage.value = "Name cannot be empty.";
    return;
  }
  errorMessage.value = null;
});
</script>

<template>
  <Dialog v-model:open="isDialogOpen">
    <DialogTrigger asChild>
      <div
        class="datasource_card_create w-full min-h-48 flex items-center justify-center gap-4 rounded-lg bg-primary20 border border-primary30 cursor-pointer transition-colors duration-200 ease-in-out hover:bg-primary30"
      >
        <div class="p-4 w-full text-center">
          <Plus class="w-6 h-6 text-foreground mx-auto" />
        </div>
      </div>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Create new prompt</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        <form @submit.prevent="handleCreateSavedPrompt">
          <div class="mb-4">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Name</label
            >
            <input
              type="text"
              id="name"
              v-model="newSavedPromptName"
              class="mt-1 p-2 border border-gray-300 rounded-md w-full"
              maxlength="25"
              required
              autocomplete="off"
            />
            <p v-if="errorMessage" class="text-red-500 text-sm">
              {{ errorMessage }}
            </p>
          </div>
          <div>
            <label for="content" class="block text-sm font-medium text-gray-700"
              >Content</label
            >
            <textarea
              id="content"
              v-model="newSavedPromptContent"
              class="description_textarea mt-1 p-2 border border-gray-300 rounded-md w-full"
              rows="10"
              maxlength="10000"
              placeholder="Enter your prompt"
              required
              autocomplete="off"
            ></textarea>
          </div>
          <div class="flex gap-2">
            <div v-if="isGlobal" class="mt-4">
              <Select v-model="selectedSkill">
                <SelectTrigger class="w-auto">
                  <SelectValue class="!flex !items-center gap-2 p-2">
                    <Brain class="text-primary shrink-0 w-5 h-5" />
                    <p class="text-black">
                      {{ selectedSkill }}
                    </p>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem
                    v-for="skill in skills"
                    :key="skill.name"
                    :value="skill.name"
                  >
                    <p class="flex items-center gap-1">
                      {{ skill.name }}
                    </p>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div v-if="isAdmin" class="mt-4">
              <Select v-model="selectedVisibility">
                <SelectTrigger class="w-auto">
                  <SelectValue class="!flex !items-center gap-2 p-2">
                    <Eye
                      v-if="selectedVisibility === Visibility.PUBLIC"
                      class="text-primary"
                    />
                    <EyeOff v-else class="text-primary" />
                    <p class="text-black">
                      {{
                        selectedVisibility.charAt(0).toUpperCase() +
                        selectedVisibility.slice(1)
                      }}
                    </p>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem
                    v-for="value in visibilityValues"
                    :key="value"
                    :value="value"
                  >
                    {{ value.charAt(0).toUpperCase() + value.slice(1) }}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div v-if="!props.isGlobal" class="flex !items-center gap-1 p-3">
            <Lightbulb class="w-4 h-4 text-yellow-500 flex-none" />
            <p
              v-if="selectedVisibility === Visibility.PRIVATE"
              class="text-xs text-gray-500"
            >
              Only you will be able to see, edit, and use this saved prompt.
            </p>
            <p
              v-else-if="selectedVisibility === Visibility.PUBLIC"
              class="text-xs text-gray-500"
            >
              All your organization members will be able to see and use this
              saved prompt. Only admins will be able to edit its content.
            </p>
          </div>
          <div v-else class="flex !items-center gap-1 p-3">
            <Lightbulb class="w-4 h-4 text-yellow-500 flex-none" />
            <p
              v-if="selectedVisibility === Visibility.PRIVATE"
              class="text-xs text-gray-500"
            >
              This prompt won't be displayed in the catalog.
            </p>
            <p
              v-else-if="selectedVisibility === Visibility.PUBLIC"
              class="text-xs text-gray-500"
            >
              This prompt will be displayed in the catalog.
            </p>
          </div>
          <DialogFooter>
            <Button variant="outline" @click="isDialogOpen = false"
              >Cancel</Button
            >
            <Button
              v-if="!isLoading"
              type="submit"
              :disabled="
                newSavedPromptName === '' || newSavedPromptContent === ''
              "
              >Create</Button
            >
            <Button v-else disabled>
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
              Create
            </Button>
          </DialogFooter>
        </form>
      </DialogDescription>
    </DialogContent>
  </Dialog>
</template>
