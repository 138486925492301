<script setup lang="ts">
import { File, Brain, Globe } from "lucide-vue-next";
import AgentCard from "@/components/ChatView/guided-agent-selection/AgentCard.vue";
import { Skills } from "@/types";
import { useToolbarStore } from "@/stores/toolbarStore";
import { useTenantStore } from "@/stores/tenant";
import DataSourceSelectorMenu from "./DataSourceSelectorMenu.vue";
import { setUserSkill } from "@/services/skill";
import { useRoute } from "vue-router";

const toolbarStore = useToolbarStore();
const route = useRoute();
const tenantStore = useTenantStore();

async function setSelectedSkill(skill: Skills): Promise<void> {
  if (toolbarStore.selectedSkill !== skill) {
    toolbarStore.setSelectedSkill(skill);
    const chat_session_id = route.params.session_id as string;
    await setUserSkill(tenantStore.tenantId as string, skill, chat_session_id);
  }
}
</script>

<template>
  <div
    class="flex flex-col h-full gap-8 justify-center items-center align-self-center flex-grow"
  >
    <div class="welcome_message font-spartan text-center">
      <h1 class="text-4xl mb-2">Welcome to Playground</h1>
      <h2 class="text-2xl font-light">How can I help you?</h2>
    </div>
    <div class="agent_cards_container w-full flex justify-center gap-4">
      <AgentCard
        :icon="File"
        :color="'bg-blue-100'"
        :title="'Chat with documents'"
        :text="'Chat or do complex tasks with your own documents'"
        :isSelected="toolbarStore.selectedSkill === Skills.CHAT_WITH_DOCUMENTS"
        @click="setSelectedSkill(Skills.CHAT_WITH_DOCUMENTS)"
      />
      <AgentCard
        v-if="toolbarStore.selectedSkill !== Skills.CHAT_WITH_DOCUMENTS"
        :icon="Brain"
        :color="'bg-yellow-100'"
        :title="'Chat with ChatGPT'"
        :text="'Chat or do complex tasks with GPT'"
        :isSelected="toolbarStore.selectedSkill === Skills.CHAT_WITH_LLM"
        @click="setSelectedSkill(Skills.CHAT_WITH_LLM)"
      />
      <AgentCard
        v-if="toolbarStore.selectedSkill !== Skills.CHAT_WITH_DOCUMENTS"
        :icon="Globe"
        :color="'bg-green-100'"
        :title="'Chat with web'"
        :text="'Chat with web data'"
        :isSelected="toolbarStore.selectedSkill === Skills.CHAT_WITH_WEB"
        @click="setSelectedSkill(Skills.CHAT_WITH_WEB)"
      />
      <DataSourceSelectorMenu />
    </div>
  </div>
</template>
