import apiClient from "@/services/api_client";

export async function exportMessage(
  tenantId: string,
  htmlContent: string
): Promise<Blob> {
  const blob = await apiClient.post(
    "/html-to-docx",
    {
      html: htmlContent,
      tenant_id: tenantId,
    },
    true
  );
  return blob;
}
