import { useTenantStore } from "@/stores/tenant";
import {
  CircleUser,
  Database,
  LibraryBig,
  MessageSquare,
  MessageSquareQuote,
  Users,
} from "lucide-vue-next";
import { createMemoryHistory, createRouter, RouteRecordRaw } from "vue-router";
import CatalogManagementView from "./views/CatalogManagementView.vue";
import ChatView from "./views/ChatView.vue";
import DataSourcesView from "./views/DataSourcesView.vue";
import DataSourceView from "./views/DataSourceView.vue";
import SavedPromptsView from "./views/SavedPromptsView.vue";
import UserManagementView from "./views/UserManagementView.vue";
import UserProfileView from "./views/UserProfileView.vue";

type tenantStoreType = ReturnType<typeof useTenantStore>;

const routes: RouteRecordRaw[] = [
  {
    path: "/chat/:session_id?",
    component: ChatView,
    name: "Chat",
    meta: {
      icon: MessageSquare,
      canAccess: () => true,
      showInSidebar: false,
    },
  },
  {
    path: "/data-sources",
    component: DataSourcesView,
    name: "Data sources",
    meta: {
      icon: Database,
      canAccess: () => true,
      showInSidebar: true,
    },
  },
  {
    path: "/data-source/:id",
    component: DataSourceView,
    name: "DataSource",
    meta: {
      icon: Database,
      canAccess: () => true,
      showInSidebar: false,
    },
  },
  {
    path: "/saved-prompts",
    component: SavedPromptsView,
    name: "Manage prompts",
    meta: {
      icon: MessageSquareQuote,
      canAccess: () => true,
      showInSidebar: true,
    },
  },
  {
    path: "/user",
    component: UserProfileView,
    name: "User profile",
    meta: {
      icon: CircleUser,
      canAccess: () => true,
      showInSidebar: true,
    },
  },
  {
    path: "/catalog-management",
    component: CatalogManagementView,
    name: "Catalog management",
    meta: {
      icon: LibraryBig,
      canAccess: (tenantStore: tenantStoreType) =>
        tenantStore.tenantName === "Elqano",
      showInSidebar: true,
    },
  },
  {
    path: "/user-management",
    component: UserManagementView,
    name: "User management",
    meta: {
      icon: Users,
      canAccess: () => true,
      showInSidebar: true,
    },
  },
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const tenantStore = useTenantStore();
  if (to.path === "/") {
    next("/chat");
    return;
  }
  const canAccess = (
    to.meta.canAccess as (tenantStore: tenantStoreType) => boolean
  )(tenantStore);
  if (canAccess) {
    next();
  } else {
    next("/user");
  }
});

export default router;
