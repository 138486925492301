<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { computed, onMounted, ref, watch } from "vue";
import { SavedPrompt, Skill, Visibility } from "@/types";
import { Save, Pen, X, Loader2, Eye, EyeOff, Brain } from "lucide-vue-next";
import { deleteSavedPrompt, updateSavedPrompt } from "@/services/saved_prompts";
import { useTenantStore } from "@/stores/tenant";
import { useUserStore } from "@/stores/user";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { getSkills } from "@/services/skill";

const props = defineProps<{
  isShowDialogOpen: boolean;
  savedPrompt: SavedPrompt | null;
  loadSavedPrompts: Function;
  isGlobal: boolean;
}>();

const emit = defineEmits(["update:isShowDialogOpen"]);

const tenantStore = useTenantStore();
const globalTenantId = "__global__";
const selectedTenant = ref<string>(
  props.isGlobal ? globalTenantId : tenantStore.tenantId || ""
);
const displayNameInput = ref(false);
const newName = ref<string | undefined>("");
const newContent = ref<string | undefined>("");
const isUpdatingContent = ref(false);
const { userId } = useUserStore();
const selectedVisibility = ref<string | undefined>(
  props.savedPrompt?.visibility
);
const visibilityValues = Object.values(Visibility);
const loadingVisibility = ref(false);
const loadingSkill = ref(false);
const skills = ref<Skill[]>([]);
const selectedSkill = ref<string | undefined>(props.savedPrompt?.skill_name);

const allowContentSave = computed(() => {
  return (
    props.savedPrompt?.content &&
    props.savedPrompt?.content !== newContent.value
  );
});

const canEdit = computed(() => {
  return (
    props.isGlobal ||
    props.savedPrompt?.author === userId ||
    (tenantStore.tenantAdmin &&
      props.savedPrompt?.visibility === Visibility.PUBLIC)
  );
});

const isShowDialogOpen = computed({
  get: () => props.isShowDialogOpen,
  set: (value: boolean) => emit("update:isShowDialogOpen", value),
});

function hideSavedPrompt() {
  isShowDialogOpen.value = false;
}

async function handleDeleteSavedPrompt() {
  if (!props.savedPrompt) {
    return;
  }
  hideSavedPrompt();

  await deleteSavedPrompt(props.savedPrompt.id, selectedTenant.value);
  await props.loadSavedPrompts();
}

async function updateName() {
  if (!props.savedPrompt || !newName.value) {
    return;
  }
  await updateSavedPrompt(
    props.savedPrompt.id,
    selectedTenant.value,
    newName.value
  );

  await props.loadSavedPrompts();
  displayNameInput.value = false;
}

async function updateContent() {
  if (!props.savedPrompt || !newContent.value) {
    return;
  }
  isUpdatingContent.value = true;
  await updateSavedPrompt(
    props.savedPrompt.id,
    selectedTenant.value,
    undefined,
    newContent.value
  );
  props.savedPrompt.content = newContent.value;
  await props.loadSavedPrompts();
  isUpdatingContent.value = false;
}

async function updateSavedPromptVisibility() {
  if (props.savedPrompt && selectedVisibility.value) {
    loadingVisibility.value = true;
    await updateSavedPrompt(
      props.savedPrompt.id,
      globalTenantId,
      undefined,
      undefined,
      selectedVisibility.value
    );
    await props.loadSavedPrompts();
    loadingVisibility.value = false;
  }
}

async function updateSavedPromptSkill() {
  if (props.savedPrompt && selectedSkill.value) {
    loadingSkill.value = true;
    await updateSavedPrompt(
      props.savedPrompt.id,
      globalTenantId,
      undefined,
      undefined,
      undefined,
      selectedSkill.value
    );
    await props.loadSavedPrompts();
    loadingSkill.value = false;
  }
}

onMounted(async () => {
  if (!props.isGlobal || !tenantStore.tenantId) {
    return;
  }
  const response = await getSkills(tenantStore.tenantId);
  skills.value = response.skills;
});

watch(
  () => selectedVisibility.value,
  (newVal, oldVal) => {
    if (newVal && oldVal && newVal !== oldVal) {
      updateSavedPromptVisibility();
    }
  }
);

watch(
  () => selectedSkill.value,
  (newVal, oldVal) => {
    if (newVal && oldVal && newVal !== oldVal) {
      updateSavedPromptSkill();
    } else if (newVal && newVal !== oldVal) {
      selectedSkill.value = newVal;
    }
  }
);

watch(
  () => props.savedPrompt,
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      newName.value = newVal.name;
      newContent.value = newVal.content;
      selectedVisibility.value = newVal.visibility;
      selectedSkill.value = newVal.skill_name;
    }
  }
);
</script>

<template>
  <Dialog v-model:open="isShowDialogOpen">
    <DialogContent class="!max-w-full !w-3/5 h-5/6 flex flex-col">
      <DialogHeader>
        <DialogTitle>Edit prompt</DialogTitle>
      </DialogHeader>
      <div class="flex gap-2 items-center">
        <DialogDescription v-if="!displayNameInput">{{
          newName
        }}</DialogDescription>
        <form
          v-if="displayNameInput"
          class="p-2 flex items-center"
          @submit.prevent="updateName"
        >
          <FormField name="name">
            <FormItem class="w-full">
              <FormControl class="w-full flex">
                <Input
                  type="text"
                  class="w-full"
                  v-model="newName"
                  @keydown.enter.prevent="updateName"
                  maxlength="25"
                  autocomplete="off"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Button
            type="submit"
            size="sm"
            class="ml-4 hover:bg-gray-200 bg-white w-6 h-6 rounded-full relative group p-0"
          >
            <Save v-if="displayNameInput" class="w-3 h-3 text-gray-800" />
            <span
              class="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 opacity-0 group-hover:opacity-100 group-hover:bg-gray-400 group-hover:text-white text-xs rounded p-1 transition-opacity duration-300 whitespace-nowrap pointer-events-none z-50"
              style="box-sizing: border-box"
            >
              Save
            </span>
          </Button>
        </form>
        <Button
          v-if="canEdit"
          @click="displayNameInput = !displayNameInput"
          size="sm"
          class="hover:bg-gray-200 bg-white w-6 h-6 rounded-full p-0 relative group"
        >
          <Pen v-if="!displayNameInput" class="w-3 h-3 text-gray-800" />
          <X v-if="displayNameInput" class="w-3 h-3 text-gray-800" />
          <span
            class="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 opacity-0 group-hover:opacity-100 group-hover:bg-gray-400 group-hover:text-white text-xs rounded p-1 transition-opacity duration-300 whitespace-nowrap pointer-events-none z-50"
          >
            Edit name
          </span>
        </Button>
      </div>
      <div class="flex gap-2">
        <Select v-model="selectedSkill" v-if="isGlobal">
          <SelectTrigger class="w-auto">
            <SelectValue
              v-if="!loadingSkill"
              class="!flex !items-center gap-2 p-2"
            >
              <Brain class="text-primary shrink-0 w-5 h-5" />
              <p class="text-black">
                {{ selectedSkill }}
              </p>
            </SelectValue>
            <SelectValue v-else class="!flex !items-center gap-2 p-2">
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectItem
              v-for="skill in skills"
              :key="skill.name"
              :value="skill.name"
            >
              <p class="flex items-center gap-1">
                {{ skill.name }}
              </p>
            </SelectItem>
          </SelectContent>
        </Select>
        <Select
          v-model="selectedVisibility"
          v-if="selectedVisibility && isGlobal"
        >
          <SelectTrigger class="w-fit">
            <SelectValue
              v-if="!loadingVisibility"
              class="!flex !items-center gap-2 p-2"
            >
              <Eye
                v-if="selectedVisibility === Visibility.PUBLIC"
                class="text-primary"
              />
              <EyeOff v-else class="text-primary" />
              <p class="text-black">
                {{
                  selectedVisibility.charAt(0).toUpperCase() +
                  selectedVisibility.slice(1)
                }}
              </p>
            </SelectValue>
            <SelectValue v-else class="!flex !items-center gap-2 p-2">
              <Loader2 class="w-4 h-4 mr-2 animate-spin" />
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectItem
              v-for="value in visibilityValues"
              :key="value"
              :value="value"
            >
              {{ value.charAt(0).toUpperCase() + value.slice(1) }}
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
      <form
        class="flex flex-col flex-grow text-sm"
        @submit.prevent="updateContent"
      >
        <textarea
          id="content"
          v-model="newContent"
          class="mt-1 p-2 border border-gray-300 rounded-md w-full flex-grow resize-none"
          maxlength="10000"
          :readonly="!canEdit"
        ></textarea>
        <div class="flex justify-between pt-4">
          <Button
            v-if="canEdit"
            @click="handleDeleteSavedPrompt"
            variant="destructive"
            class="bg-red-400"
          >
            Delete
          </Button>
          <Button
            v-if="canEdit"
            :disabled="!allowContentSave || isUpdatingContent"
            type="submit"
          >
            <Loader2
              v-if="isUpdatingContent"
              class="w-4 h-4 mr-2 animate-spin"
            />
            Save
          </Button>
        </div>
      </form>
    </DialogContent>
  </Dialog>
</template>
