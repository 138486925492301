<script setup lang="ts">
import { DataSource } from "@/types";
import { computed } from "vue";
import type { Component } from "@vue/runtime-core";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Database,
  Loader2,
  Globe,
  LockKeyhole,
  ChevronDown,
} from "lucide-vue-next";
import { Checkbox } from "@/components/ui/checkbox";

type Props = {
  dataSources: DataSource[];
  displayedSelectedDataSources: string;
  selectedDataSources: string[];
  loadingDataSources: boolean;
  menuOpen: boolean;
  icon?: Component;
  boxed?: boolean;
};

const {
  dataSources,
  displayedSelectedDataSources,
  selectedDataSources,
  loadingDataSources,
  menuOpen,
  icon,
  boxed,
} = defineProps<Props>();

const emit = defineEmits(["update:menuOpen", "update:selectedDataSources"]);

const publicDatasources = computed(() => {
  return dataSources.filter((dataSource) => dataSource.visibility === "public");
});
const privateDatasources = computed(() => {
  return dataSources.filter(
    (dataSource) => dataSource.visibility === "private"
  );
});

const boxedStyle = computed(() => {
  return boxed ? "!shadow-md" : "";
});

const selectedStyle = computed(() => {
  return boxed && selectedDataSources.length === 0 && !loadingDataSources
    ? "bg-accent"
    : "";
});

const selectorClass = computed(() => {
  return `${boxedStyle.value} ${selectedStyle.value}`.trim();
});

const selectorButtonClass = computed(() => {
  return !boxed ? "h-full py-2 pl-4 pr-5 hover:bg-primary10" : "";
});

function handleCheckboxChange(isChecked: boolean, dataSource: DataSource) {
  const updatedDataSources = [...selectedDataSources];
  const index = updatedDataSources.indexOf(dataSource.name);
  if (isChecked) {
    if (!updatedDataSources.includes(dataSource.name)) {
      updatedDataSources.push(dataSource.name);
    }
  } else {
    if (index > -1) {
      updatedDataSources.splice(index, 1);
    }
  }
  emit("update:selectedDataSources", updatedDataSources);
}

function toggleCheckbox(event: Event, dataSource: DataSource) {
  event.preventDefault();
  const isChecked = !selectedDataSources.includes(dataSource.name);
  handleCheckboxChange(isChecked, dataSource);
}
</script>

<template>
  <DropdownMenu
    v-if="dataSources.length > 0"
    :open="menuOpen"
    @update:open="(value) => emit('update:menuOpen', value)"
  >
    <DropdownMenuTrigger
      as-child
      class="!shadow-md !border-0 justify-start truncate w-full"
      :class="selectorClass"
    >
      <Button
        variant="outline"
        class="flex items-center justify-between gap-2 pl-4 pr-3 py-4 truncate"
        :class="selectorButtonClass"
      >
        <div class="flex items-center gap-2">
          <component :is="icon" v-if="icon" />
          <Database v-else class="shrink-0 text-primary w-5 h-5" />
          <p class="truncate font-normal" v-if="!loadingDataSources">
            {{ displayedSelectedDataSources }}
          </p>
        </div>
        <Loader2 v-if="loadingDataSources" class="animate-spin h-5 w-5" />
        <ChevronDown class="shrink-0 w-4 h-4 ml-2" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent side="bottom" align="start" class="overflow-hidden">
      <div
        class="overflow-y-auto"
        :class="
          boxed ? 'min-w-64 max-w-96 max-h-[45vh]' : 'min-w-96 max-h-[35vh]'
        "
      >
        <div class="my-2" v-if="privateDatasources.length > 0">
          <DropdownMenuLabel
            class="privacy_tag text-muted-foreground font-medium flex gap-2 items-center h-fit w-fit mb-2 rounded-full px-2 py-1 bg-primary20 mt-1"
          >
            <LockKeyhole class="h-3 w-3" />
            <p class="text-xs">Private</p>
          </DropdownMenuLabel>
          <DropdownMenuItem
            v-for="dataSource in privateDatasources"
            :key="dataSource.id"
            class="flex items-center gap-2 cursor-pointer truncate"
            @select="toggleCheckbox($event, dataSource)"
          >
            <Checkbox
              :checked="selectedDataSources.includes(dataSource.name)"
              @update:checked="handleCheckboxChange($event, dataSource)"
              @click.stop
            />
            <span class="truncate">{{
              dataSource.chat_session_id ? "Attached files" : dataSource.name
            }}</span>
          </DropdownMenuItem>
        </div>
        <div v-if="publicDatasources.length > 0">
          <DropdownMenuSeparator />
          <DropdownMenuLabel
            class="privacy_tag text-muted-foreground font-medium flex gap-2 items-center h-fit w-fit mb-2 rounded-full px-2 py-1 bg-primary20 mt-2"
          >
            <Globe class="h-3 w-3" />
            <p class="text-xs">Public</p>
          </DropdownMenuLabel>
          <DropdownMenuItem
            v-for="dataSource in publicDatasources"
            :key="dataSource.id"
            class="flex items-center gap-2 cursor-pointer truncate"
            @select="toggleCheckbox($event, dataSource)"
          >
            <Checkbox
              :checked="selectedDataSources.includes(dataSource.name)"
              @update:checked="handleCheckboxChange($event, dataSource)"
              @click.stop
            />
            <span class="truncate">{{ dataSource.name }}</span>
          </DropdownMenuItem>
        </div>
      </div>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
